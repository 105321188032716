





























































import useAbstract from "@/use/abstract";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { saveAs } from "file-saver";

export default defineComponent({
  components: {
    MBulkReviewersAssign: () =>
      import("@/components/molecules/abstracts/m-bulk-reviewers-assign.vue"),
    MReportsGenerate: () =>
      import("@/components/molecules/abstracts/m-reports-generate.vue"),
  },

  props: {
    abstractList: {
      type: Array,
      required: false,
      default: [],
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      loading: false,
      loaded: false,
      alerts: [],
      totalAlerts: 0,
      error: false as boolean | number,
      abstractList: [] as any,
      alertSuccess: false,
      alertDialog: false,
      statusSuccess: false,
      statusDialog: false,
      participantGroups: [],
      totalGroups: 0,
      groupsDialog: false,
    });

    const model = reactive({
      assignReviewers: false,
      acceptAbstract: false,
      rejectAbstract: false,
      changeType: false,
      type: undefined,
    });

    const { typeItems } = useAbstract({ root });

    watch(
      () => props.abstractList,
      () => {
        state.abstractList = props.abstractList.map((item: any) => item["id"]);
      }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const changeStatusOrType = (status: number) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        abstracts: state.abstractList,
        type: status === 1 || status === 2 ? undefined : model.type,
        status: status,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/abstract/bulk`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.statusSuccess = true;
          state.statusDialog = true;
          state.error = false;

          state.abstractList = [];
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const emitFetch = () => {
      emit("fetch");
    };

    const getAbstractBook = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/abstract-report/book`, {
          responseType: "blob",
        })
        .then((response) => {
          saveAs(response.data, "ksiazka-streszczen.docx");
        })
        .catch((error) => console.log(error));
    };

    return {
      state,
      model,

      hasAccessTo,

      changeStatusOrType,

      typeItems,
      emitFetch,
      getAbstractBook,
    };
  },
});
